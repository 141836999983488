import { Injectable } from '@angular/core';
import { Observable } from "rxjs/Observable";
import { Utilities } from '../../helpers/utilities';

import { SurveyUrl } from '../constants/survey-url';
import { GeneralSettingUrl } from '../../general-setting/constants/general-setting-url';

@Injectable()
export class SurveyService {

  dataProgressSurveyExpired: any;

  constructor(private _u: Utilities) {
  }

  getSurveyList(page: number, per_page: number, lat?: any, long?: any): Observable<any> {
    if (lat && long) {
      return this._u.req.get(SurveyUrl.listSurvey + '?page=' + page + '&per_page=' + per_page + '&lat=' + lat + '&long=' + long);
    } else {
      return this._u.req.get(SurveyUrl.listSurvey + '?page=' + page + '&per_page=' + per_page);
    }
  }

  getSurveyListInProgress(page: number, per_page: number, device_token: any): Observable<any> {
    return this._u.req.get(SurveyUrl.listSurveyInProgress + '?page=' + page + '&per_page=' + per_page + '&device_token=' + device_token);
  }

  doSurvey(data: any): Observable<any> {
    return this._u.req.post(SurveyUrl.postSurvey, data);
  }

  completeSurvey(data: any): Observable<any> {
    return this._u.req.post(SurveyUrl.completeSurvey, data);
  }

  getDataProgressSurveyExpired() {
    return this.dataProgressSurveyExpired;
  }

  setDataProgressSurveyExpired(dataProgressSurveyExpired: any) {
    this.dataProgressSurveyExpired = dataProgressSurveyExpired;
  }

  getCheckboxStatusInGeneralSetting(): Observable<any> {
    return this._u.req.get(GeneralSettingUrl.statusInGeneralSetting);
  }

  shareSurveyAfterComplete(): Observable<any> {
    return this._u.req.get(GeneralSettingUrl.shareLinkSurveyCompleted);
  }

  getAccountInfo(): Observable<any>{
    return this._u.req.get(GeneralSettingUrl.accountInfo);
  }

  sendRelevantIdService(request: any): Observable<any> {
    return this._u.req.post(SurveyUrl.relevantIdService, request);
  }

  sendCSOService(request: any): Observable<any> {
    return this._u.req.post(SurveyUrl.csoService, request);
  }
}
