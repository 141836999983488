import { Injectable } from '@angular/core';
import { Observable } from "rxjs/Observable";
import { Utilities } from '../../helpers/utilities';

import { GeneralSettingUrl } from '../constants/general-setting-url';

@Injectable()
export class GeneralSettingService {

    constructor(private _u: Utilities) { 
    }

    getGeneralConfig() : Observable<any>{
        return this._u.req.get(GeneralSettingUrl.generalConfiguration);
    }

    getCheckboxStatusInGeneralSetting(): Observable<any>{
        return this._u.req.get(GeneralSettingUrl.statusInGeneralSetting);
    }

    updateCheckboxStatusInGeneralSetting(data: any): Observable<any>{
        return this._u.req.post(GeneralSettingUrl.statusInGeneralSetting, data);
    }
}